<template>
  <main-layout>
    <section class="p-5 overflow-auto">
      <h2>Pagina niet gevonden</h2>
      <p>De pagina die je zoekt bestaat niet.</p>
      <p class="action">
        <router-link to="/" class="btn btn-outline-primary">
          ← Terug naar Zaantje
        </router-link>
      </p>
    </section>
  </main-layout>
</template>

<script>
import MainLayout from 'components/MainLayout';

export default {
  components: { MainLayout },
};
</script>

<style scoped>
h2 {
  margin-bottom: 1.5rem;
  text-transform: none;
  font-size: 1.25rem;
  font-weight: normal;
}

p.action {
  margin-top: 1.5rem;
}

section {
  padding-bottom: 1rem;
}
</style>
