<template>
  <main-layout>
    <section class="p-5 overflow-auto">
      <section>
        <h2>Ontdek hip-hop uit jouw buurt en neem een tour door Zaantje.</h2>
        <p>
          Op Zaantje verzamelen we alle hip-hop muziekvideo's die in Zaandam en
          omstreken zijn opgenomen. Met één klik op de kaart ontdek je lokale
          artiesten en kom je er achter welke grote artiesten hier geclipt
          hebben.
        </p>
        <p class="action">
          <router-link to="/" class="btn btn-outline-primary">
            Bekijk de kaart →
          </router-link>
        </p>
      </section>
      <section>
        <p>
          👨‍💻 door
          <a
            href="mailto:wiebe@offday.nl"
            class="text-decoration-none"
            target="_blank"
            >@wiebekaai</a
          >
          en
          <a
            href="https://hellob.art/"
            class="text-decoration-none"
            target="_blank"
            >@bartvdbraak</a
          >
        </p>
      </section>
    </section>
  </main-layout>
</template>

<style scoped lang="scss">
h2 {
  margin-bottom: 1.5rem;
  text-transform: none;
  font-size: 1.25rem;
  font-weight: normal;
}

p.action {
  margin-top: 1.5rem;
}

section {
  padding-bottom: 1rem;
}
</style>

<script>
import MainLayout from 'components/MainLayout';

export default {
  components: { MainLayout },
};
</script>
