<template>
  <main class="layout">
    <header>
      <router-link @click.native="home" to="/" class="logo" :title="appName">
        <img :src="require('assets/zaantje.svg')" :alt="appName" />
      </router-link>
      <navigation class="main" @navigating="navigating" />

      <button class="btn back" v-if="showBack" @click="$emit('back')">
        <fa-icon icon="arrow-left" class="fa-fw" />
      </button>

      <button class="btn hamburger" @click="showMenu = !showMenu">
        <fa-icon :icon="showMenu ? 'times' : 'bars'" class="fa-fw" />
      </button>
    </header>
    <main>
      <navigation v-if="showMenu" class="mobile" @navigating="navigating" />
      <slot></slot>
    </main>
  </main>
</template>

<script>
import Navigation from 'components/Navigation';

export default {
  props: {
    showBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appName: 'Zaantje',
      showMenu: false,
    };
  },
  methods: {
    home() {
      this.navigatedHome(false);

      this.showMenu = false;
    },
    navigating(path) {
      if (path === '/') {
        this.navigatedHome(true);
      }

      this.showMenu = false;
    },
    navigatedHome(fullReset) {
      this.$emit('home', fullReset);
    },
  },
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';
@import '~bootstrap/scss/bootstrap-grid';

main.layout {
  touch-action: manipulation;
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-areas: 'header' 'body';

  main {
    overflow-y: auto;
    position: relative;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: unset;
  flex-flow: row wrap;
  background: #2c2b2c;
  padding: 0 1.4rem;

  a {
    margin: 0 auto;
    width: 100px;
  }

  button {
    display: flex;
    position: absolute;

    &.back {
      left: 0;
    }

    &.hamburger {
      right: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  header {
    a {
      margin: unset;
    }

    button {
      display: none;
    }

    nav.main {
      display: inline-block;
    }
  }

  main {
    nav {
      display: none;
    }
  }
}
</style>
