import SanityClient from './SanityClient';

export default {
  async getLocation(id) {
    const results = await SanityClient.fetch(
      `*[_type == 'location' && _id == $id] {_id, name, description, position, 'videos': *[_type == 'video' && references(^._id)]{_id, 'title': youtube_video.title, 'thumbnail': youtube_video.thumbnail.asset}}`,
      { id }
    );
    return results[0];
  },
  async getLocations() {
    return await SanityClient.fetch(
      `*[_type == 'location'] {..., 'videos': *[_type == 'video' && references(^._id)]}[count(videos) > 0]{_id, name, description, position, 'image': videos[0].youtube_video.thumbnail}`
    );
  },
};
