<template>
  <nav @focusout="clearPreview" tabindex="0">
    <section class="controls-wrapper">
      <section class="controls" ref="controls">
        <fa-icon icon="fast-backward" class="rewind fa-fw" @click="rewind" />
      </section>
      <timeline-bar
        ref="timelineBar"
        :timestamps="timestamps"
        :duration-seconds="duration"
        :passed-seconds="passed"
        @timestampClick="timestampClicked"
        @timestampMouseEnter="timestampMouseEnter"
        @timestampMouseLeave="timestampMouseLeave"
      />
    </section>
    <section class="preview" v-if="previewTimestamp">
      <h3>
        <fa-icon icon="map-marker-alt" class="icon fa-fw" size="sm" />
        {{ previewTimestamp.location.name }}
      </h3>
      <button
        v-if="this.isMobile()"
        class="btn btn-primary"
        type="button"
        @mousedown.prevent
        @click.prevent="timestampSelected(previewTimestamp)"
      >
        <fa-icon icon="eye" class="fa-fw" />
      </button>
      <div :style="timestampPreviewArrowStyle" class="arrow" />
    </section>
  </nav>
</template>

<script>
import TimelineBar from 'components/TimelineBar';
import DetectMobile from '#/mixins/DetectMobile';

export default {
  mixins: [DetectMobile],
  components: { TimelineBar },
  props: {
    duration: {
      type: Number,
    },
    passed: {
      type: Number,
    },
    timestamps: {
      type: Array,
    },
  },
  data() {
    return {
      previewTimestamp: null,
    };
  },
  methods: {
    timestampSelected(timestamp) {
      this.clearPreview();
      this.$emit('timestampSelected', timestamp);
    },
    timestampClicked(timestamp) {
      if (this.isMobile()) {
        this.previewTimestamp = timestamp;
      } else {
        this.timestampSelected(timestamp);
      }
    },
    timestampMouseEnter(timestamp) {
      if (this.isMobile()) return;

      this.previewTimestamp = timestamp;
    },
    timestampMouseLeave() {
      if (this.isMobile()) return;

      this.clearPreview();
    },
    rewind() {
      this.$emit('rewind');
      this.clearPreview();
    },
    clearPreview() {
      this.previewTimestamp = null;
    },
  },
  computed: {
    timestampPreviewArrowStyle() {
      const left =
        this.$refs.timelineBar.$refs.bar.clientWidth *
        (this.previewTimestamp.timestamp / this.duration);
      const offset = this.$refs.controls.clientWidth;
      return `left: calc(${left}px + ${offset}px);`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

$preview-background: $bg-secondary;

nav {
  outline: none;

  .preview {
    width: 100%;
    padding: 0.5rem 1rem;
    background: $preview-background;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 0.5rem;
    height: 2.5rem;

    h3 {
      .icon {
        color: $marker-active;
      }

      font-size: 0.8rem;
      margin: 0 auto;
    }

    button {
      height: 1.5rem;
      padding: 0 0.5rem;
      align-self: end;
    }

    .arrow {
      bottom: 100%;
      left: 50%;
      content: ' ';
      height: 0;
      width: 0;
      border: 0.5rem solid transparent;
      margin-left: 0.5rem;
      position: absolute;
      border-bottom-color: $preview-background;
    }
  }

  .controls-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 0.5rem;

    .rewind {
      cursor: pointer;
    }
  }
}
</style>
