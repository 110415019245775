<template>
  <base-panel icon="map-marker-alt" :title="location.name">
    <article class="description" v-if="location.description">
      <p v-if="location.description.length <= 250">
        {{ location.description }}
      </p>
      <span v-else>
        <p v-if="!readMore">
          {{ location.description.slice(0, 250) }}...
          <a href="#" class="text-primary" @click.prevent="readMore = true"
            >Meer <fa-icon size="sm" icon="chevron-right"></fa-icon
          ></a>
        </p>
        <p v-else>
          {{ location.description }}
        </p>
      </span>
    </article>
    <nav class="videos">
      <video-thumbnail
        v-for="video in videos"
        :key="video._id"
        :video="video"
        @selected="videoSelected"
      />
    </nav>
  </base-panel>
</template>
<script>
import VideoThumbnail from 'components/VideoThumbnail';
import BasePanel from 'components/BasePanel';

export default {
  components: { BasePanel, VideoThumbnail },
  props: {
    location: {
      type: Object,
      required: true,
    },
    video: {
      type: Object,
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
  methods: {
    videoSelected(video) {
      this.$emit('videoSelected', video._id);
    },
  },
  computed: {
    videos() {
      if (!this.video) {
        return this.location.videos;
      }

      return this.location.videos.filter((video) => {
        return video._id !== this.video._id;
      });
    },
  },
};
</script>

<style scoped>
.description p,
.link {
  font-size: 0.9rem;
}

.description p {
  margin-bottom: 0.2rem;
}

.videos {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  min-height: 90px;
  display: flex;
  flex-flow: wrap row;
  position: relative;
}

.videos article {
  display: flex;
  flex-flow: column;
  flex: 50% 0;
  padding: 0.5rem;
}

@media screen and (min-width: 1224px) {
  .videos article {
    flex: 33.3% 0;
  }
}
</style>
