<template>
  <main-layout :show-back="hasVideoOrLocation" @back="this.back" @home="reset">
    <main class="columns" :class="{ 'with-sidebar': hasVideoOrLocation }">
      <section class="content">
        <button
          class="collapse-sidebar"
          @click="back"
          v-if="hasVideoOrLocation"
        >
          <fa-icon icon="times" class="fa-fw" />
        </button>
        <google-maps
          :key="refreshMapKey"
          :location="location"
          :locations="locations"
          :video-locations="videoLocations"
          :loading="loadingMaps"
          @locationSelected="locationSelected"
        />
      </section>
      <aside>
        <base-panel v-if="video" icon="play" :title="video.title" class="mb-1">
          <video-player
            :location="location"
            @locationChanged="locationSelected"
            :video="video"
            :key="video._id"
          />
        </base-panel>
        <location-panel
          v-if="location"
          :location="location"
          :video="video"
          :key="location._id"
          @videoSelected="videoSelected"
          class="location-panel"
        />
      </aside>
    </main>
  </main-layout>
</template>

<script>
import GoogleMaps from 'components/ExploreGoogleMaps';
import VideoPlayer from 'components/VideoPlayer';
import LocationPanel from 'components/LocationPanel';
import BasePanel from 'components/BasePanel';
import MainLayout from 'components/MainLayout';

import VideosRepository from 'repositories/VideosRepository';
import LocationsRepository from 'repositories/LocationsRepository';

export default {
  components: {
    MainLayout,
    BasePanel,
    GoogleMaps,
    LocationPanel,
    VideoPlayer,
  },
  data() {
    return {
      videoLocations: [],
      loadingMaps: false,
      location: null,
      video: null,
      refreshMapKey: 0,
      locations: [],
    };
  },
  mounted() {
    this.retrieveLocations();
  },
  methods: {
    async retrieveLocations() {
      this.loadingMaps = true;
      this.locations = await LocationsRepository.getLocations();
      this.loadingMaps = false;
    },
    async retrieveVideoLocations() {
      if (!this.video) {
        this.videoLocations = [];
      } else {
        this.loadingMaps = true;

        const video = await VideosRepository.getVideo(this.video._id);

        this.videoLocations = video.timestamp_locations.map(
          (timestampLocation) => {
            return timestampLocation.location;
          }
        );

        this.loadingMaps = false;
      }
    },
    async videoSelected(videoId) {
      this.video = await VideosRepository.getVideo(videoId);
    },
    async locationSelected(locationId) {
      const videoContainsLocation = this.videoLocations.find(
        (l) => l._id === locationId
      );

      if (!videoContainsLocation) {
        this.video = null;
      }

      this.location = await LocationsRepository.getLocation(locationId);
    },
    back() {
      if (this.video) {
        this.video = null;
      } else if (this.location) {
        this.location = null;
      }
    },
    reset() {
      if (!this.video && !this.location) {
        this.refreshMapKey++;
      }

      this.video = null;
      this.location = null;
    },
  },
  computed: {
    hasVideoOrLocation() {
      return Boolean(this.video || this.location);
    },
  },
  watch: {
    video() {
      this.retrieveVideoLocations();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';
@import '~bootstrap/scss/bootstrap-grid';

$sidebarTransition: 0.5s;

main.columns {
  display: block;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  overflow: hidden;

  section.content {
    position: relative;
    height: 100%;

    button.collapse-sidebar {
      position: absolute;
      z-index: 10;
      right: 0;
      border: 0;
      background: $bg-primary;
      color: $white;
      line-height: 2rem;
      font-size: 1.125rem;
      top: 1rem;

      svg {
        border-radius: 0.2rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }

  aside {
    display: none;
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
  }

  &.with-sidebar {
    section.content {
      display: none;
    }

    aside {
      display: block;
    }
  }
}

@include media-breakpoint-up(md) {
  main.columns {
    display: flex;
    flex-flow: row nowrap;

    section.content,
    aside {
      transition: all ease $sidebarTransition;
    }

    section.content {
      flex: 1;
    }

    aside {
      display: block;
      flex: 0;
      width: 0;
      padding: 0;
    }

    &.with-sidebar {
      section.content {
        display: block;
      }

      aside {
        flex: 1;
        padding: 1rem;
      }
    }
  }
}
</style>
