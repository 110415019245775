import Vue from 'vue';
import 'animate.css/animate.min.css';
import VueYoutube from 'vue-youtube';
import * as VueGoogleMaps from 'vue2-google-maps';
import VClamp from 'vue-clamp';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';

import 'sass/app.scss';
import './registerServiceWorker';

Vue.config.productionTip = false;

library.add(fas);

Vue.use(VueYoutube);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
  autobindAllEvents: false,
  installComponents: true,
});

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('v-clamp', VClamp);

new Vue({
  router,
  watch: {
    $route: {
      handler: (to) => {
        document.title = `${to.meta.title} | Zaantje`;
      },
      immediate: true,
    },
  },
}).$mount('#app');
