<template>
  <section class="map-container">
    <loading-overlay
      v-if="loading"
      message="De buurt wordt verkend..."
      icon="🗺️"
    />
    <GmapMap
      ref="mapRef"
      :center="gmapOptions.center"
      @zoom_changed="zoomChanged"
      :options="gmapOptions"
      map-type-id="terrain"
      class="map"
    >
      <CustomMarker
        :key="index"
        v-for="(marker, index) in markers"
        :marker="marker"
        @click.native="onMarkerClick(marker)"
        :zoom="zoom"
        :image="marker.location.image"
        :class="getMarkerClass(marker.location)"
      />
    </GmapMap>
  </section>
</template>

<script>
import GoogleMapOptions from '#/GoogleMapOptions';
import LoadingOverlay from 'components/LoadingOverlay';
import CustomMarker from 'components/MapsMarker';

export default {
  components: { CustomMarker, LoadingOverlay },
  props: {
    location: {
      type: Object,
    },
    videoLocations: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    locations: {
      type: Array,
    },
  },
  data() {
    return {
      gmapOptions: GoogleMapOptions,
      zoom: GoogleMapOptions.zoom,
      lineOptions: GoogleMapOptions.lineOptions,
    };
  },
  methods: {
    getMarkerClass: function (location) {
      if (!this.location && !this.videoLocations.length) {
        return null;
      }

      if (location._id === this.location._id) {
        return 'active';
      }

      if (
        this.videoLocations.find(
          (videoLocation) => videoLocation._id === location._id
        )
      ) {
        return 'highlighted';
      }

      return 'inactive';
    },
    zoomChanged(value) {
      if (this.zoom !== value) {
        this.zoom = value;
      }
    },
    onMarkerClick: function (marker) {
      this.$emit('locationSelected', marker.location._id);
    },
    panToLocation: function (location) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.setZoom(16);
        map.panTo(location.position);
      });
    },
  },
  watch: {
    location: function () {
      if (!this.location) {
        return;
      }

      this.panToLocation(this.location);
    },
  },
  computed: {
    markers() {
      return this.locations.map((location) => {
        return {
          position: location.position,
          location: location,
        };
      });
    },
  },
};
</script>

<style scoped>
.map-container,
.map {
  width: 100%;
  height: 100%;
}
</style>
