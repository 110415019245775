<template>
  <section class="video-player position-relative">
    <loading-overlay v-if="loading" message="Video wordt geladen..." />

    <youtube
      :video-id="this.video.video_id"
      :fit-parent="true"
      :resize="true"
      :player-vars="{ fs: 0, color: 'white', rel: 0, playsinline: 1 }"
      ref="youtube"
      @playing="playing"
      @paused="paused"
    />

    <section class="toolbar my-2" :class="{ 'px-2': mobile }">
      <timeline
        @rewind="rewind"
        @timestampSelected="tagSelected"
        :passed="currentTime"
        :duration="duration"
        :timestamps="this.video.timestamp_locations"
      />
    </section>
  </section>
</template>

<script>
import Timeline from 'components/Timeline';
import LoadingOverlay from 'components/LoadingOverlay';

export default {
  components: { LoadingOverlay, Timeline },
  props: {
    video: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processId: 0,
      currentTime: 0,
      duration: 0,
      activeTag: null,
      loading: false,
      videoId: '',
    };
  },
  methods: {
    async playing() {
      this.playerInterval();
      this.processId = setInterval(this.playerInterval, 500);
    },
    playerInterval() {
      this.player.getCurrentTime().then((time) => {
        this.currentTime = time;
        this.updateActiveTag();
        this.loading = false;
      });
    },
    paused() {
      clearInterval(this.processId);
    },
    updateActiveTag() {
      // Filter tags (only passed tags)
      const passedTags = this.video.timestamp_locations.filter((value) => {
        return this.currentTime >= value.timestamp;
      });

      // Get last passed tags
      if (passedTags.length) {
        const lastPassedTag = passedTags.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });

        if (!this.activeTag || this.activeTag !== lastPassedTag) {
          this.activeTag = lastPassedTag;
          this.$emit('locationChanged', lastPassedTag.location._id);
        }
      } else {
        this.activeTag = null;
      }
    },
    async tagSelected(tag) {
      await this.player.seekTo(tag.timestamp, true);
      this.player.playVideo();
    },
    async rewind() {
      await this.player.seekTo(0, true);
      this.player.playVideo();
    },
    async goToLocation(location) {
      clearInterval(this.processId);

      const locationTags = await this.video.timestamp_locations.filter(
        (tag) => {
          return tag.location._id === location._id;
        }
      );

      if (locationTags.length) {
        // For now use the first appearance of the location in the video
        const locationTag = locationTags[0];
        await this.tagSelected(locationTag);
      }
    },
    async initialize() {
      this.loading = true;

      this.player.getDuration().then((duration) => {
        this.duration = duration;
      });

      if (this.location) {
        await this.goToLocation(this.location);
      }

      this.loading = false;
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    clearInterval(this.processId);
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    location: {
      handler: async function (value) {
        if (this.activeTag && this.activeTag.location._id === value._id) {
          return;
        }

        await this.goToLocation(this.location);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.video-player {
  display: block;
  min-height: 200px;

  iframe {
    width: 100%;
  }
}
</style>
