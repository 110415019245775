<template>
  <section class="overlay" :class="small ? 'smallOverlay' : ''">
    <section>
      <p class="h1"><fa-icon icon="cog" class="spin" /></p>
      <p v-if="message">{{ this.message }}️</p>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~sass/variables';

.overlay {
  z-index: 5;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba($bg-primary, 0.9);
  text-align: center;
}

.overlay.smallOverlay .h1 {
  font-size: 1.5rem;
}

.spin {
  -webkit-animation: fa-spin 4s infinite linear;
  animation: fa-spin 4s infinite linear;
}
</style>
