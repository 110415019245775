<template>
  <article :class="{ active: hover }">
    <section
      class="thumbnail-wrapper"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :title="video.title"
    >
      <div class="overlay" @click="$emit('selected', video)">
        <fa-icon :icon="['fas', 'play']" class="text-center overlay-icon" />
      </div>
      <div class="img-wrapper">
        <img
          class="img-thumb"
          :srcset="generateSrcset"
          sizes="(max-width: 500px) 240px, (max-width: 1440px) 480px, 640px"
        />
      </div>
    </section>

    <h2
      @click="$emit('selected', video)"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :title="video.title"
    >
      <v-clamp autoresize :max-lines="2">{{ video.title }}</v-clamp>
    </h2>
  </article>
</template>

<script>
import SanityImageUrlBuilder from '@/repositories/SanityImageUrlBuilder';

export default {
  data() {
    return {
      hover: false,
      sizes: ['640', '480', '240'],
    };
  },
  props: {
    video: {
      required: true,
    },
  },
  computed: {
    generateSrcset() {
      let srcsetString = ``;
      let sizesLength = this.sizes.length;

      while (sizesLength--) {
        const width = this.sizes[sizesLength];
        const imageUrl = SanityImageUrlBuilder.image(
          this.video.thumbnail
        ).maxWidth(width);

        srcsetString += `${imageUrl}`;

        if (sizesLength !== 0) {
          srcsetString += `,\n`;
        }
      }
      return srcsetString;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~sass/variables';

h2 {
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: none;
  margin: 0;
}

.thumbnail-wrapper {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.img-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 55%;
  object-fit: cover;
}

.img-thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: opacity ease-in-out 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay > * {
  opacity: 0;
  transition: none;
}

@media (hover: hover) {
  article.active .overlay {
    opacity: 1;
  }

  article.active .overlay > * {
    opacity: 1;
  }
}

.overlay-icon {
  width: 30%;
  height: 30%;
}
</style>
