<template>
  <section class="bar-wrapper">
    <div class="bar" ref="bar">
      <div class="fill" :style="fillStyle"></div>
      <article
        v-for="timestamp in timestamps"
        :key="timestamp._id"
        :style="timestampStyle(timestamp)"
        @click="$emit('timestampClick', timestamp)"
        @mouseenter="$emit('timestampMouseEnter', timestamp)"
        @mouseleave="$emit('timestampMouseLeave', timestamp)"
        :class="timestampClasses(timestamp)"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    timestamps: {
      type: Array,
      required: true,
    },
    passedSeconds: {
      type: Number,
      required: true,
    },
    durationSeconds: {
      type: Number,
      required: true,
    },
  },
  methods: {
    timestampStyle(timestamp) {
      const left = (timestamp.timestamp / this.durationSeconds) * 100;
      return 'left:' + left + '%;';
    },
    getActiveTimestamp() {
      const passedTimestamps = this.timestamps.filter((timestamp) => {
        return this.passedSeconds >= timestamp.timestamp;
      });

      if (!passedTimestamps.length) {
        return null;
      }

      return passedTimestamps[passedTimestamps.length - 1];
    },
    timestampClasses(timestamp) {
      return {
        highlighted: this.passedSeconds >= timestamp.timestamp,
        active:
          this.getActiveTimestamp() && this.getActiveTimestamp() === timestamp,
      };
    },
  },
  computed: {
    percentage() {
      return (this.passedSeconds / this.durationSeconds) * 100;
    },
    fillStyle() {
      return 'width:' + this.percentage + '%';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

$bar-background: $bg-secondary;
$bar-fill: lighten($bg-secondary, 50);

.bar-wrapper {
  flex: 1 auto;
  padding-left: 1rem;
  padding-right: 0.5rem;

  .bar {
    height: 0.3rem;
    background: $bar-background;
    position: relative;
    align-items: center;
    display: flex;

    .fill {
      height: 100%;
      width: 0;
      background: $bar-fill;
      transition: width ease 0.2s;
      z-index: 1;
    }

    article {
      background: $bar-background;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      display: block;
      position: absolute;
      margin-left: -0.5rem;
      transition: border-color, background-color 0.3s;
      cursor: pointer;
      z-index: 5;

      &.highlighted {
        z-index: 10;
        background: $marker-highlighted;
      }

      &.active {
        background: $marker-active;
      }
    }
  }
}
</style>
