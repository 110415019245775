import Vue from 'vue';
import VueRouter from 'vue-router';
import Explore from 'components/views/Explore';
import About from 'components/views/About';
import NotFound from 'components/views/404';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Explore,
      meta: { title: 'Ontdekken', name: 'Ontdekken' },
    },
    {
      path: '/wat',
      component: About,
      meta: { title: 'Rappers in Zaandam', name: 'Wat?' },
    },
    { path: '*', component: NotFound, meta: { title: 'Pagina niet gevonden' } },
  ],
});
