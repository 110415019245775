<template>
  <GmapCustomMarker
    alignment="center"
    :marker="marker.position"
    class="marker"
    :class="sizeClass"
  >
    <img :src="markerImage" :alt="marker.location.name" />
  </GmapCustomMarker>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import SanityImageUrlBuilder from '@/repositories/SanityImageUrlBuilder';

export default {
  components: { GmapCustomMarker },
  props: {
    image: {
      type: Object,
      required: true,
    },
    zoom: {
      default: 14,
      type: Number,
    },
    marker: {
      required: true,
      type: Object,
    },
  },
  computed: {
    sizeClass() {
      if (this.zoom <= 12) {
        return;
      }

      if (this.zoom <= 14) {
        return 'md';
      }

      return 'lg';
    },
    markerImage() {
      return SanityImageUrlBuilder.image(this.image)
        .width(100)
        .height(100)
        .url();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

$marker-sm: 30px;
$marker-md: 45px;
$marker-lg: 75px;

.marker {
  width: $marker-sm;
  height: $marker-sm;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid $marker;
  cursor: pointer;
  transition-property: transform, width, height;
  transition-duration: 0.25s;

  img {
    width: 100%;
    height: 100%;
  }

  &.lg {
    width: $marker-lg;
    height: $marker-lg;
  }

  &.md {
    width: $marker-md;
    height: $marker-md;
  }

  &.active {
    border-color: $marker-active;
  }

  &.highlighted {
    border-color: $marker-highlighted;
  }

  &.inactive {
    z-index: 45 !important;
    transform: scale(0.8);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
