import SanityClient from '@/repositories/SanityClient';

export default {
  async getVideo(id) {
    const result = await SanityClient.fetch(
      `*[_type == 'video' && _id == $id] { _id, 'title': youtube_video.title, 'video_id': youtube_video.video_id, timestamp_locations[]{timestamp, location->{_id, name}}}`,
      { id }
    );
    return result[0];
  },
};
