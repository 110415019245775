<template>
  <main class="px-2">
    <h1 class="h5">
      <fa-icon v-if="icon" :icon="icon" size="sm" class="icon fa-fw" />{{
        title
      }}
    </h1>
    <slot></slot>
  </main>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
h1 {
  line-height: 2rem;
}

.icon {
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  height: 1rem;
  width: 1rem;
}
</style>
