<template>
  <nav>
    <ul>
      <li v-for="item in items" :key="item.meta.name">
        <router-link
          @click.native="$emit('navigating', item.path)"
          exact-active-class="active"
          :to="item.path"
        >
          {{ item.meta.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import router from '#/router';

export default {
  data() {
    return {
      items: router.options.routes.filter((r) => r.path !== '*'),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

nav {
  &:not(.mobile) {
    display: none;
    margin-left: 2rem;

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      height: 100%;

      li {
        width: 5rem;
        display: inline-block;
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: $white;
          text-decoration: unset;

          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }
}

nav.mobile {
  background: $bg-secondary;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding: 1rem;
      text-align: center;

      a {
        text-decoration: none;
        color: $white;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
